.app-container {
    width: auto;
    height: auto;
    text-align: center;
    background-color: #f3f3f3;
}

.app-container h1 {
    font-size: 22px;
    margin: auto auto 28px;
    width: 212px;
    height: 27px;
    font-weight: bold;
    line-height: 0.91;
    text-align: center;
    color: #000000;
}

.description {
    margin-bottom: 35px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.22;
    color: #000000;
}

.services-container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    max-width: 1120px;
    padding: unset;
    justify-content: center;
}

.services-container li {
    width: 256px;
    height: 245px;
    margin-left: 20px;
    margin-bottom: 25px;
}

.services-container .service-card {
    display: flex;
    flex-direction: column;
    width: 256px;
    height: 218px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-left: solid 5px #3467df;
}

.service-card  h2 {
    width: 160px;
    height: auto;
    margin: 20px auto 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.09;
    text-align: center;
    color: #666666;
}

.service-card  span {
    margin-left: auto;
    margin-right: auto;
    max-width: 212px;
    height: 75px;
    text-align: center;
    font-size: 16px;
    line-height: 1.13;
    color: #666666;
    background-color: #ffffff;
}

a {
    margin-top: 160px;
    margin-left: 34px;
    position: absolute;
    text-decoration: none;
    width: 189px;
    height: 40px;
    object-fit: contain;
    border-radius: 50px;
    background-color: #e1173f;
}

.disabled {
    background-color: #666666 !important;
}

a p {
    font-size: 14px;
    font-weight: bold;
    line-height: 0.9;
    text-align: center;
    color: #ffffff;
}
