
.header__general {
    width: 95%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin: 0 auto;
}

.header__logo {
    flex: 1 1 80px;
}

.header__logo-link {
    display: block;
}

.logo-text {
    display: none;
}

@media (min-width: 750px) {
    .logo-text {
        display: block;
    }
}
